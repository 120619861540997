import React from 'react';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Styles from './page.module.css';

export default function Page({ data }) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <SEO title={frontmatter.title} />

      <div className={Styles.post}>
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <ul className="nav nav-pills flex-column">
                <li className="nav-item">
                  <Link className="nav-link" to="/about">About</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/terms-of-use">Terms of Use</Link>
                </li>
              </ul>
            </div>

            <div className="col-md-9">
              <article>
                <h1>{frontmatter.title}</h1>
                <p className="text-right">
                  <i className="material-icons">update</i>
                  {frontmatter.date}
                </p>
                <div
                  className="blog-post-content"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              </article>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: {path: {eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`;

Page.propTypes = {
  data: PropTypes.object.isRequired,
};
